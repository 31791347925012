<template>
    <v-card color="white">
        <h2 class="pa-2 blue white--text">{{FAQ.name}}</h2> 
        <div class="pa-2 blue lighten-4 dark--text body-1"><b></b> 
            <v-layout row wrap>
                <v-flex xs6 text-xs-left>
                    <span class="dark--text font-weight-bold">Rating:</span><span> {{FAQ.ratings}}</span>
                </v-flex>
                <v-flex xs6 text-xs-right>
                    <span class="dark--text font-weight-bold">Likes:</span><span> {{FAQ.likes}}</span>
                </v-flex>
                <v-flex xs6 text-xs-left>
                    <span class="dark--text font-weight-bold ">Category:</span><span> {{FAQ.category}}</span>
                </v-flex>
                <v-flex xs6 text-xs-right>
                    <span class="dark--text font-weight-bold ">Dislikes:</span><span> {{FAQ.dislikes}}</span>
                </v-flex>
            </v-layout>
        </div>
        <v-divider></v-divider>
        <v-card-text>
            <p class="mt-2" ><b>Description:</b> <br> {{FAQ.description}}</p>
            <p v-html="FAQ.steps" class="pt-2 ma-0" ></p>
        </v-card-text>
    </v-card>
</template>


<script>
    import axios from 'axios'
    
    export default {
        data() {
            return {
                FAQ: [],
            }
        },

        methods:{
            getFAQDetail(){
                const faq_id = this.$route.params.key  // get campaign category from url and send request to server
                axios
                    .get('/api/v1/home/get/faqs/info/'+faq_id)
                    .then(response => {
                        this.FAQ = response.data  // get the data and fill into campaigns
                        console.log(this.FAQ)
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
            },
        },
        
        mounted(){
            // executes these after the page has been mounted
            this.getFAQDetail();
            this.$store.commit('setCloseURL', "/faqs/types/savings")
        },
    }
</script>

